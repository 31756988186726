// api.js
import axios from 'axios';
const { REACT_APP_API_ENDPOINT } = process.env;
const api = axios.create({
  baseURL: `${REACT_APP_API_ENDPOINT}`,
});


// Funzione per ottenere le campagne
export const getUfficioPostaleId = async (selectedUfficio) => {
  try {
    const response = await api.get(`/api/managed/ufficio?denominazione=${selectedUfficio}`);
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero del deposito:', error);
    throw error;
  }
};