// api.js
import createAuthInterceptor from '../../../utils/authInterceptor';
import axios from 'axios';
const { REACT_APP_API_ENDPOINT } = process.env;
const axiosInstance = createAuthInterceptor();
// Funzione per ottenere le campagne
export const getCampaignsAffissioni = async () => {
  try {
    const response = await axiosInstance.get(`${REACT_APP_API_ENDPOINT}/api/stat/depositi/campaigns-menu`);
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero delle campagne:', error);
    throw error;
  }
};

// Funzione per ottenere le regioni
export const getDepostiRegions = async () => {
  try {
    const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/stat/depositi/regioni`);
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero delle regioni:', error);
    throw error;
  }
};

// Funzione per ottenere le città in base alla regione
export const getDepositiCities = async (region) => {
  try {
    const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/stat/depositi/citta?regione=${region}`);
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero delle città:', error);
    throw error;
  }
};

