const { REACT_APP_API_ENDPOINT } = process.env;
export async function fetchUffici(){
    const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/offices/offices_all`);
    return response.json();
}

export async function fetchUfficio(){
    const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/offices/getone`);
    return response.json();
}

export async function addUfficio(officeData){
    const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/offices/create`,{
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(officeData) 
    });
    if (response.status === 401) {
        return Promise.reject(new Error("Username o email già esistenti. Si prega di utilizzare un altro username o email."))
    }
    if (response.status === 400 || response.status === 500) {
        return Promise.reject(new Error("Ooops!"))
    }
    return response.json();
}

export async function updateUfficio(officeData){
    const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/offices/update`,{
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(officeData) 
    });
    if (response.status === 401) {
        return Promise.reject(new Error("Username o email già esistenti. Si prega di utilizzare un altro username o email."))
    }
    if (response.status === 400 || response.status === 500) {
        return Promise.reject(new Error("Ooops!"))
    }
    return response.json();
}



