import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import StoreIcon from '@mui/icons-material/Store';
import BarChartIcon from '@mui/icons-material/BarChart';


export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
     setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    //  setSelectedIndex(index);
    if (index === 1) {
          navigate(`/home/admin/dash-depositi`)
      }
      if (index === 2) {
          navigate('/home/admin/panel-depositi');
      }
      if (index === 3) {
        navigate('/home/admin/dash-uff');
      }
      if (index === 4) {
      navigate('/home/admin/panel-affissioni');
      }
      if (index === 5) {
        navigate('/home/admin/dash-dep');
        }  
      setAnchorEl(null);
    };

  const navigate = useNavigate();

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{color: 'inherit'}}
      >
        Admin
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

      <MenuItem onClick={(event) => handleMenuItemClick(event, 5)}>
      <ListItemIcon>
      <BarChartIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Dash Depositi</ListItemText>
      
      </MenuItem>

       <MenuItem onClick={(event) => handleMenuItemClick(event, 2)}>
       <ListItemIcon>
       <AirportShuttleIcon fontSize="small" />
          
         </ListItemIcon>
         <ListItemText>Panel Depositi</ListItemText>

       </MenuItem>

       
       <MenuItem onClick={(event) => handleMenuItemClick(event, 3)}>
       <ListItemIcon>
           <BarChartIcon  fontSize="small" />
         </ListItemIcon>
         <ListItemText>Dash Affissioni</ListItemText>
       </MenuItem>


       <MenuItem onClick={(event) => handleMenuItemClick(event, 4)}>
       <ListItemIcon>
       <StoreIcon fontSize="small" />
         </ListItemIcon>
         <ListItemText>Panel Affissioni</ListItemText>
       
       </MenuItem>




      </Menu>
    </>
  );
}
