import React, { useState, useEffect } from 'react';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
const { REACT_APP_API_ENDPOINT } = process.env;


const CarSearchAndUpdate = () => {
  const [targa, setTarga] = useState('');
  const [campagne, setCampagne] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [stato, setStato] = useState('');
  const [autoInfo, setAutoInfo] = useState(null);

  // Carica le campagne disponibili
  useEffect(() => {
    axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns`).then((res) => {
      setCampagne(res.data);
    });
  }, []);

  const handleSearch = async () => {
    const res = await axios.get(`${REACT_APP_API_ENDPOINT}/api/cars/${targa}`);
    console.log(res)
    setAutoInfo(res.data);
  };

  const handleUpdate = async () => {
    if (!selectedCampaign || !autoInfo) return;
  
    try {
      await axios.post(
        `${REACT_APP_API_ENDPOINT}/api/auto-campagna/aggiorna`,
        {
          campaignId: selectedCampaign,
          autoId: autoInfo._id,
          garageId: autoInfo.garageId,
          stato,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
  
      alert('Stato aggiornato con successo');
    } catch (error) {
      console.error('Errore durante l\'aggiornamento dello stato:', error);
      alert('Errore durante l\'aggiornamento dello stato');
    }
  };
  

  return (
    <div>
      <TextField
        label="Inserisci targa"
        value={targa}
        onChange={(e) => setTarga(e.target.value)}
      />
      <Button onClick={handleSearch}>Cerca Auto</Button>

      {autoInfo && (
        <>
          <div>
          <Grid container spacing={2}>

            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            {`${autoInfo.marca} - ${autoInfo.modello} - ${autoInfo.targa}`}
            </Typography >

          </Grid>

          <FormControl sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Campagna</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={selectedCampaign}
            onChange={(e) => setSelectedCampaign(e.target.value)}
            autoWidth
            label="Campagna"
          >
          {campagne.map((campagna) => (
            <MenuItem key={campagna._id} value={campagna._id}>
              {campagna.denominazione}
            </MenuItem>
          ))}
          </Select>
        </FormControl>
      
          </div>

          <FormControl sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-autowidth-label">Stato</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={stato}
            onChange={(e) => setStato(e.target.value)}
            autoWidth
            label="Campagna"
          >
          <MenuItem value="in_progress">In Progress</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
          </Select>
        </FormControl>


          <Button onClick={handleUpdate}>Aggiorna Stato</Button>
        </>
      )}
    </div>
  );
};

export default CarSearchAndUpdate;
