import React from 'react';
import ImageUpload from '../components/ImagesUpload';
import { Container, Typography, Box } from '@mui/material';

function Upload() {
  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Carica le tue immagini
        </Typography>
        <ImageUpload />
      </Box>
    </Container>
  );
}

export default Upload;
