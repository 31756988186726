// api.js
import axios from 'axios';
const { REACT_APP_API_ENDPOINT } = process.env;
const api = axios.create({
  baseURL: `${REACT_APP_API_ENDPOINT}`,
});


// Funzione per ottenere le campagne
export const getCampaigns = async () => {
  try {
    const response = await api.get('/api/campaigns-menu');
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero delle campagne:', error);
    throw error;
  }
};

// Funzione per ottenere le regioni
export const getRegions = async () => {
  try {
    const response = await api.get('/api/regioni');
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero delle regioni:', error);
    throw error;
  }
};

// Funzione per ottenere le città in base alla regione
export const getCities = async (region) => {
  try {
    const response = await api.get(`/api/citta?regione=${region}`);
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero delle città:', error);
    throw error;
  }
};

// Funzione per ottenere i garage in base alla città
export const getGarages = async (city) => {
  try {
    const response = await api.get(`/api/indirizzo?citta=${city}`);
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero dei garage:', error);
    throw error;
  }
};

export const getUfficioDepositoId = async (garage) => {
  try {
    const response = await api.get(`/api/denominazione?recapito=${garage}`);
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero dei garage:', error);
    throw error;
  }
};


