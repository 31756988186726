import axios from 'axios';

const API_URL = 'http://localhost:5000/api/images';  // L'URL del backend

// Funzione per recuperare le immagini dell'utente
const getUserImages = async () => {
  try {
    const token = localStorage.getItem('token');
    
    // Configurazione degli headers con il token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Chiamata GET al backend
    const response = await axios.get(`${API_URL}/user-images`, config);

    return response.data; // Restituisce le immagini ricevute dal backend
  } catch (error) {
    console.error('Errore nel recupero delle immagini:', error);
    throw error; // Solleva un errore per gestirlo nel componente React
  }
};

export default getUserImages;
