import React, {useEffect, useState} from 'react';
import axios from 'axios';
import garage from './garages.json';
import depositi from './depositi.json'
//import preuffici from './preUffici.json';
import ufficiPostali from './ufficiPostali.json'
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
//import fs from 'fs'
import localPost from './home_pin_32.svg'
import {APIProvider, 
  Map, 
  Marker, 
  InfoWindow,
  AdvancedMarker,
  Pin,
  AdvancedMarkerAnchorPoint} from '@vis.gl/react-google-maps';

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

function MapGoogle() {

  const [hoverId, setHoverId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [anchorPoint, setAnchorPoint] = useState('BOTTOM');
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindowShown, setInfoWindowShown] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState(null);

  const onMouseEnter = (id) => setHoverId(id);
  const onMouseLeave = (() => setHoverId(null), []);
  const onMarkerClick = ((id, marker) => {
      setSelectedId(id);

      if (marker) {
        setSelectedMarker(marker);
      }

      if (id !== selectedId) {
        setInfoWindowShown(true);
      } else {
        setInfoWindowShown(isShown => !isShown);
      }
    },
    [selectedId]
  );


 // let garageLungo = garage.length

 // var array = [];
  var array2 = [];

  const getData =  (indirizzo, citta)=>{

    if (indirizzo && citta) {

      axios({
        method: 'get',
        url: `https://maps.googleapis.com/maps/api/geocode/json?address=${indirizzo} ${citta},+it&key=AIzaSyBCvGSjTkj-ZM_J4m5pQ16E-SsYsGHTZzA`,
      })
        .then(function (response) {
       //   console.log(response.data.results[0].geometry);
           array2.push(response.data.results[0])
       //  return response.data.results[0]
          
       //   response.data(fs.createWriteStream('ada_lovelace.json'))
        });
      
    }


  };
 // for (let index = 0; index < garage.length; index++) {
 /*
  for (let index = 0; index < preuffici.length; index++) {
    const indiri = preuffici[index].indirizzo;
    const citta = preuffici[index].citta;
    getData(indiri, citta);    
    
  }
*/
 



 // console.log(JSON.stringify(garage))

  const position = {lat: 41.924598693847656, lng: 12.445199012756348};
  const style = { width: "80vw", height: "80vh" };


  const clickMarker = (props) => {
    console.log("type", props);
    setSelectedCenter(props)
  };

  const handleInfowindowCloseClick = (
    () => setInfoWindowShown(false),
    []
  );

  return (
    <div className="App" style={style}>
     
    {/* 
    <APIProvider apiKey={GOOGLE_MAPS_API_KEY} onLoad={() => console.log('Maps API has loaded.')}>
    <Map
      style={{width: '100vw', height: '100vh'}}
      defaultCenter={{lat: 41.902782, lng: 0}}
      defaultZoom={3}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
    />
  </APIProvider>
*/}
  <APIProvider apiKey={REACT_APP_GOOGLE_MAPS_API_KEY} onLoad={() => console.log('Maps API has loaded.')}>
  
  <Map
      defaultZoom={6.5}
      defaultCenter={position}
      style={{ width: "50vw", height: "100vh" }}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
      mapId='67cf8c0012e507fa'
      /* onCameraChanged={ (MapCameraChangedEvent) =>
        console.log('camera changed:', MapCameraChangedEvent.detail.center, 'zoom:', MapCameraChangedEvent.detail.zoom)
      } */
    >
      { 
        depositi?.map((coords, index) => (
          <AdvancedMarker
            key={index}
            position={coords.geometry.location}
            onClick={()=> clickMarker(coords)}
            placeId={coords.place_id}
          />
        ))
      
      }

      {ufficiPostali?.map((coords, index) => (
        <>
          <AdvancedMarker
            key={index}
            position={coords.geometry.location}
            onClick={()=> clickMarker(coords)}

            
            placeId={coords.place_id}
            icon={{
              url: localPost,  
              fillOpacity: 0.5,   
           // anchor: new  window.google.maps.Point(17, 46),    
          //  scaledSize: new window.google.maps.Size(46, 46)      
          }}
          onMarkerClick={(
            marker ) => onMarkerClick(index, marker)}
          onMouseEnter={() => onMouseEnter(index)}
          onMouseLeave={onMouseLeave}
          zIndex={index}
          className="custom-marker"
          style={{
            transform: `scale(${[hoverId, selectedId].includes(index) ? 1.3 : 1})`,
            transformOrigin: AdvancedMarkerAnchorPoint['BOTTOM'].join(' ')
          }}
          >
          <Pin
              background={"#004899"}
              borderColor={"#0a32c3"}
              glyphColor={"#E4102C"}
            >
            </Pin>
  
            </AdvancedMarker>
</>
          
        ))}

        {selectedCenter && (
          <InfoWindow
             onCloseClick={() => {
                setSelectedCenter(null);
             }}
             position={selectedCenter?.geometry.location}
          >
          <div>
          <LocalPostOfficeIcon />
          <h5 style={{color: 'black'}}>{selectedCenter.formatted_address}</h5>
        </div>
          </InfoWindow>
       )}
    </Map>   
    </APIProvider>
    </div>
  );
}

export default MapGoogle;
