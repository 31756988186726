import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (event, index) => {

    if (index === 1) {
      //  navigate(`/home/admin/dash`)
    }
    if (index === 2) {
      //  navigate('/home/admin/');
    }
    if (index === 3) {
      navigate('/home/admin/registation');
    }
    if (index === 4) {
     // navigate('/home/admin/panel-affissioni');
    } 
    if (index === 5) {
        localStorage.removeItem('token');
        // Reindirizza l'utente alla pagina di login
        navigate('/login');
    } 
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <MenuItem 
        onClick={(event) => handleMenuItemClick(event, 1)}
         >
        <Avatar /> Profilo
        </MenuItem>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 2)}>
          <Avatar /> Account
        </MenuItem>
        <Divider />

        <MenuItem onClick={(event) => handleMenuItemClick(event, 3)}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Aggiungi account
        </MenuItem>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 4)}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Preferenze
        </MenuItem>

        <MenuItem onClick={(event) => handleMenuItemClick(event, 5)}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Quit
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
