import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
  //table
  import Table from '@mui/material/Table';
  import TableBody from '@mui/material/TableBody';
  import TableCell from '@mui/material/TableCell';
  import TableContainer from '@mui/material/TableContainer';
  import TableRow from '@mui/material/TableRow';
  import Paper from '@mui/material/Paper';
  import createAuthInterceptor from '../utils/authInterceptor';

const UfficiSearchAndUpdate = () => {
  const navigate = useNavigate();

  const axiosInstance = createAuthInterceptor(navigate);

  const [denominazione, setDenominazione] = useState('');

  const [campagne, setCampagne] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [stato, setStato] = useState('');

  const [ufficioInfo, setUfficioInfo] = useState(null);
  // Carica le campagne disponibili
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    axiosInstance.get(`${REACT_APP_API_ENDPOINT}/api/campaigns-affissioni`).then((res) => {
      setCampagne(res.data);
    });
  }, []);

  // cerco l'ufficio tramite denominazione
  const handleSearch = async () => {
    const res = await axios.get(`${REACT_APP_API_ENDPOINT}/api/uffici/${denominazione}`);
    console.log(res)
    setUfficioInfo(res.data);
  };

  const handleUpdate = async () => {
    if (!selectedCampaign || !ufficioInfo) return;
  
    try {
      await axios.post(
        `${REACT_APP_API_ENDPOINT}/api/ufficio-campagna/aggiorna`,
        {
          campaignId: selectedCampaign,
          ufficioId: ufficioInfo._id,
          stato,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
  
      alert('Stato aggiornato con successo');
    } catch (error) {
      console.error('Errore durante l\'aggiornamento dello stato:', error);
      alert('Errore durante l\'aggiornamento dello stato');
    }
  };
  

  return (
    <div>
    <Stack direction="row" spacing={2} useFlexGap sx={{ flexWrap: 'wrap',  p: 2  }} >
    <TextField
    label="Inserisci denominazione uff"
    id='crcaUfficio'
    value={denominazione}
    onChange={(e) => setDenominazione(e.target.value.toUpperCase())}
  />
      <Button variant="contained" onClick={handleSearch}>Cerca Ufficio Postale
      </Button>
    </Stack>


      {ufficioInfo && (
        <>
          <div>
          <TableContainer component={Paper} size={'small'}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            
            <TableBody>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="right" sx={{padding: '6px'}}>{ufficioInfo.regione}</TableCell>
                  <TableCell align="right" sx={{padding: '6px'}}>{ufficioInfo.citta}</TableCell>
                  <TableCell align="right" sx={{padding: '6px'}}>{ufficioInfo.denominazione}</TableCell>
                  <TableCell align="right" sx={{padding: '6px'}}>{ufficioInfo.Iindirizzo}</TableCell>
                </TableRow>
     

            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="row" spacing={2} sx={{ flexWrap: 'wrap',  p: 2  }}>
        <FormControl sx={{ m: 1, minWidth: 180 }}>
        <InputLabel id="demo-simple-select-autowidth-label">Campagna</InputLabel>
        <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={selectedCampaign}
            onChange={(e) => setSelectedCampaign(e.target.value)}
            autoWidth
            label="Campagna"
        >
            {campagne.map((campagna) => (
                <MenuItem key={campagna._id} value={campagna._id}>
                    {campagna.denominazione}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
    <FormControl sx={{ m: 1, minWidth: 180 }}>
    <InputLabel id="demo-simple-select-autowidth-label">Stato</InputLabel>
    <Select
      labelId="demo-simple-select-autowidth-label"
      id="demo-simple-select-autowidth"
      value={stato}
      onChange={(e) => setStato(e.target.value)}
      autoWidth
      label="Campagna"
    >
    <MenuItem value="in_progress">In Progress</MenuItem>
    <MenuItem value="completed">Completed</MenuItem>
    </Select>
  </FormControl>
        <Button variant="contained" onClick={handleUpdate}>
        Aggiorna Stato
        </Button>
      </Stack>
      
          </div>
        </>
      )}
    </div>
  );
};

export default UfficiSearchAndUpdate;
