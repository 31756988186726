import { useMemo, useState, useEffect } from 'react';
import {
  MRT_EditActionButtons as mRt_EditActionButtons,
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
    MenuItem,  
  } from '@mui/material';
  //Icons Imports

  
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
//import "./style_table2.css";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
const { REACT_APP_API_ENDPOINT } = process.env;

const Example = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [garages, setGarages] = useState([]);


  useEffect(() => {

    const fetchImages = async () => {
        try {
          const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/depositi/garages`);
          let test = [];
          test = response.data.data;
          setGarages(test);
        } catch (error) {
          console.error('Errore nel recupero delle immagini:', error);
         
        }
      };
  
      fetchImages(); // Chiamata alla funzione
    
    }, []);

  const columns = useMemo(

    /*
"_id": "66fd164584c023ba5b3748d1",
      "marca": "PIAGGIO",
      "modello": "PORTER BIFUEL",
      "targa": "GH655YM",
      "garageId": "66f7bd9cbf7b41110a6cff5e",
      "autoindeposito"

    */
    () => [
      {
        accessorKey: '_id',
        header: '_id',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'marca',
        header: 'marca',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.marca,
          helperText: validationErrors?.marca,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              marca: undefined,
            }),
          //optionally add validation checking for onBlur or onChange
        },
      },
      {
        accessorKey: 'modello',
        header: 'modello',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.modello,
          helperText: validationErrors?.modello,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              modello: undefined,
            }),
        },
      },
      {
        accessorKey: 'targa',
        header: 'targa',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.targa,
          helperText: validationErrors?.targa,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              targa: undefined,
            }),
        },
      },
      {
        accessorKey: `garageId`,
        header: 'garageId',
       // enableEditing: false,
       editVariant: 'select',
        editSelectOptions: JSON.stringify(garages),
        muiEditTextFieldProps: () => ({
            children: garages.map((garage, index) => <MenuItem key={garage.recapito} value={garage._id}>
              {garage}
            </MenuItem>),
            select: true,
            error: !!validationErrors?.garage,
            helperText: validationErrors?.garage,
          })
      },
    ],
    [validationErrors],
  );

  //call CREATE hook
  const { mutateAsync: createMezzo, isPending: isCreatingMezzo } = useCreateMezzo();
  //call READ hook
  const {
    data: { data = [], meta } = {},
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetMezzo();
  //call UPDATE hook
  const { mutateAsync: updateMezzo, isPending: isUpdatingUser } =
    useUpdateMezzo();
  //call DELETE hook
  const { mutateAsync: deleteUser, isPending: isDeletingUser } =
    useDeleteUser();

  //CREATE action
  const handleCreateMezzo = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await createMezzo(values);
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveMezzo = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateMezzo(values);
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      deleteUser(row.original.id);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    initialState: {
        showColumnFilters: true,
        showGlobalFilter: true,
        columnPinning: {
          left: ['mrt-row-actions'],
        },
  
      },
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateMezzo,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveMezzo,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Inserisci mezzo</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <mRt_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Edit User</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <mRt_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
      >
        Create nuovo mezzo
      </Button>
    ),
    state: {
      isLoading: isLoadingUsers,
      isSaving: isCreatingMezzo || isUpdatingUser || isDeletingUser,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers,
    },
  });

  return <MaterialReactTable table={table} className="css-1k4gpqb" />;
};

//CREATE hook (post new user to api)
function useCreateMezzo() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newMezziInfo) => {
      queryClient.setQueryData(
        ['mezzi'],
        (prevMezzi) =>
          [
            ...prevMezzi,
            {
              ...newMezziInfo,
              id: (Math.random() + 1).toString(36).substring(7),
            },
          ],
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['mezzi'] }), //refetch users after mutation, disabled for demo
  });
}

//READ hook (get users from api)
function useGetMezzo() {
  return useQuery({
    queryKey: ['mezzi'],
    queryFn: async () => {
        const fetchURL = new URL(`${REACT_APP_API_ENDPOINT}/api/flotta/mezzi`);
        //use whatever fetch library you want, fetch, axios, etc
        const response = await fetch(fetchURL.href);
        const json = (await response.json());
        return json;
    },
    refetchOnWindowFocus: false,
  });
}

//UPDATE hook (put user in api)
function useUpdateMezzo() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newMezziInfo) => {
      queryClient.setQueryData(['mezzi'], (prevMezzi) =>
        prevMezzi?.map((prevUser) =>
          prevUser.id === newMezziInfo.id ? newMezziInfo : prevUser,
        ),
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['mezzi'] }), //refetch users after mutation, disabled for demo
  });
}

//DELETE hook (delete user in api)
function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (mezzoId) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (mezzoId) => {
      queryClient.setQueryData(['mezzi'], (prevMezzi) =>
        prevMezzi?.filter((user) => user.id !== mezzoId),
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['mezzi'] }), //refetch users after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

const ParcoAuto = () => (
  //Put this with your other react-query providers near root of your app
  <QueryClientProvider client={queryClient}>
    <Example />
  </QueryClientProvider>
);

export default ParcoAuto;

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

function validateUser(user) {
  return {
    firstName: !validateRequired(user.firstName)
      ? 'First Name is Required'
      : '',
    lastName: !validateRequired(user.lastName) ? 'Last Name is Required' : '',
    email: !validateEmail(user.email) ? 'Incorrect Email Format' : '',
  };
}
