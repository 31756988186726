// api.js
import React from 'react';
import axios from 'axios';
import createAuthInterceptor from '../utils/authInterceptor';
import { useNavigate } from 'react-router-dom';

const { REACT_APP_API_ENDPOINT } = process.env;
const api = axios.create({
  baseURL: `${REACT_APP_API_ENDPOINT}`,
});

// Funzione per ottenere le campagne
export const useGetCampaignsAffissioni = async () => {
  const navigate = useNavigate();
  const axiosInstance = createAuthInterceptor(navigate);

  try {
    const response = await axiosInstance.get('/api/campaigns-affissioni');
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero delle campagne:', error);
    throw error;
  }
};

// Funzione per ottenere le regioni
export const getAffissioniRegions = async () => {
  
  try {
    const response = await api.get('/api/affissioni/regions');
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero delle regioni:', error);
    throw error;
  }
};

// Funzione per ottenere le città in base alla regione
export const getAffissioniCities = async (region) => {
  try {
    const response = await api.get(`/api/affissioni/citta?region=${region}`);
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero delle città:', error);
    throw error;
  }
};


// Funzione per ottenere gli uffici in base alla città
export const getUfficiAffisione = async (city) => {
  try {
    const response = await api.get(`/api/affissioni/uffici?citta=${city}`);
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero dei garage:', error);
    throw error;
  }
};


// Funzione per ottenere gli uffici in base alla città
export const getUfficioAffisioneId = async (denominazione) => {
  try {
    console.log("ufficio")
    const response = await api.get(`/api/affissioni/ufficio-id?denominazione=${denominazione}`);
    return response.data;
  } catch (error) {
    console.error('Errore nel recupero dei garage:', error);
    throw error;
  }
};
