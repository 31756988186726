import axios from 'axios';
import { enqueueSnackbar } from 'notistack'; // Assicurati di importare notistack se usi le notifiche

const { REACT_APP_API_ENDPOINT } = process.env;

const createAuthInterceptor = (navigate) => {
  const axiosInstance = axios.create({
    baseURL: REACT_APP_API_ENDPOINT, // Assicurati che il baseURL sia corretto
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  // Interceptor per le richieste
  axiosInstance.interceptors.request.use(
    (config) => {
      // Aggiungi il token di autorizzazione, se presente
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Interceptor per le risposte
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Controllo se c'è una risposta con un errore
      if (error.response) {
        const status = error.response.status;

        // Gestione degli errori 401 (non autorizzato) e 403 (accesso negato)
        if (status === 401) {
          // Sessione scaduta, effettua il logout
          enqueueSnackbar('Sessione scaduta. Effettua di nuovo il login.', { 
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
          localStorage.removeItem('token'); // Rimuovi il token scaduto
          navigate('/login'); // Reindirizza alla pagina di login
        } else if (status === 403) {
          // Accesso negato
          enqueueSnackbar('Accesso negato. Non hai i permessi necessari.', { 
            variant: 'error',
            anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }, 
      });
      localStorage.removeItem('token'); // Rimuovi il token scaduto
          navigate('/login'); // Reindirizza alla pagina di login
        } else {
          // Gestione di altri errori
          enqueueSnackbar('Errore nel recupero dei dati. Riprova più tardi.', { variant: 'error' });
        }
      } else {
        // Errore di rete o altri problemi
        enqueueSnackbar('Errore di rete. Verifica la tua connessione.', { variant: 'error' });
      }

      // Rifiuta la promessa per gestire l'errore nelle chiamate Axios
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
export default createAuthInterceptor;