import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Button, TextField, Box } from '@mui/material';
import Container from '@mui/material/Container';
const { REACT_APP_API_ENDPOINT } = process.env;


const ImagesUpload = () => {
  const [images, setImages] = useState([]);
  const [location, setLocation] = useState('');
  const [activity, setActivity] = useState('');
  const [uploadMessage, setUploadMessage] = useState('');

  // Funzione di dropzone per gestire i file trascinati
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: true,
    onDrop: (acceptedFiles) => {
      setImages([...images, ...acceptedFiles]);
    },
  });

  // Funzione per inviare le immagini al backend
  const handleUpload = async (e) => {
    e.preventDefault();

    if (images.length === 0 || !location || !activity) {
      alert('Please fill all fields and select images');
      return;
    }

    const formData = new FormData();
    images.forEach((image) => {
      formData.append('images', image); // Aggiungi ogni immagine a FormData
    });
    formData.append('location', location); // Aggiungi il campo location
    formData.append('activity', activity); // Aggiungi il campo activity

    try {
 
      axios.post(`${REACT_APP_API_ENDPOINT}/api/images/upload`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error('Errore durante il caricamento:', error);
      });

      setUploadMessage('Images uploaded successfully');
 
      setImages([]); // Resetta le immagini dopo il caricamento
    } catch (error) {
      console.error('Error uploading images:', error);
      setUploadMessage('Error uploading images');
    }
  };

  return (
    <Container maxWidth="sm">
    <Box>
      <h2>Upload Images</h2>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #cccccc',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
      >
        <input {...getInputProps()} />
        <p>Drag & drop some files here, or click to select files</p>
      </div>

      {/* Mostra i nomi dei file selezionati */}
      {images.length > 0 && (
        <div>
          <h4>Selected Images:</h4>
          <ul>
            {images.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}

      <TextField
      margin="normal"
        label="Location"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        fullWidth
        required
      />
      <br />
      <TextField
      margin="normal"
        label="Activity"
        value={activity}
        onChange={(e) => setActivity(e.target.value)}
        fullWidth
        required
      />
      <br />
      <Button type="submit" variant="contained" color="primary" onClick={handleUpload}>
        Upload
      </Button>
      {uploadMessage && <p>{uploadMessage}</p>}
      </Box>
      </Container>
  );
};

export default ImagesUpload;
