import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

export default function UserMenuAttivita() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event, index) => {

    if (index === 11) {
      navigate('/home/automobile/');
      }
      if (index === 12) {
      navigate('/home/uffici');
      }
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{color: 'inherit'}}
      >
        Attività
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
      <MenuItem onClick={(event) => handleClose(event, 11)} id='mezzi'>Valida Mezzi</MenuItem>
        <MenuItem onClick={(event) => handleClose(event, 12)} id='uffici'>Valida uffici</MenuItem>
      </Menu>
    </>
  );
}
 