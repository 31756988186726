import { useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_EditActionButtons,
} from 'material-react-table';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import {
  Box,
  Button,
Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Checkbox, FormControl, FormControlLabel, Typography, MenuItem, Select, InputLabel
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import TextField from '@mui/material/TextField';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { lime } from '@mui/material/colors';
import { purple } from '@mui/material/colors';
import { addUsers, fetchUsers } from './UserApi';
//nested data is ok, see accessorKeys in ColumnDef below
// Create a client
const queryClient = new QueryClient();

console.log(MRT_EditActionButtons);
const Utenti = () => {


  const[options, setOption] = useState();
  const [userValues, setUserValues] = useState({})
  const [open, setOpen] = useState(false);
  const handleChange = (event) => {setOpen(event.target.value)};

  const handleClose = (value) => {
    setOpen(false);
  };
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: '_id',
        header: '_id',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'username',
        header: 'Username',
      },
      {
        accessorKey: 'nome',
        header: 'Nome',
      },
      {
        accessorKey: 'cognome',
        header: 'Cognome',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'password',
        header: 'PW',
      },
      {
        accessorKey: 'role',
        header: 'Ruolo',
      },
    ],
    [],
  );

  const {
    data: { data = [], meta } = {},
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
    isRefetching
  } = useGetUsers();

  console.log(useGetUsers());

  const handleCancel = (table, row) => {
    table.setCreatingRow(null);
    console.log(table, row)
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      console.log(row.original.id)
     // deleteUser(row.original.id);
    }
  };

  //call CREATE hook
//  const { mutateAsync: createUser, isPending: isCreatingUser } = useCreateUser();
    //CREATE action

      //CREATE action

/*   const handleCreateUser = async ({ values, table, UserValues}) => {
    await createUser(userValues);
    table.setCreatingRow(null); //exit creating mode
  };
 */

  /* const handleCreateUser = async () => {
    console.log(userValues)
  } */
const queryClient = useQueryClient();
const createPostMutation = useMutation({
  mutationFn: addUsers,
  onSuccess: () => {
    queryClient.invalidateQueries({queryKey:['users']})
    table.setCreatingRow(null);
  },
  onError: (error) => alert(error)
})

const handleCreateUser = () => {
  createPostMutation.mutate(userValues)

}
  const handleClickOpen = () => {
    setOpen(true);
  };

 
  const table = useMaterialReactTable({
    columns,
  data: data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
   createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    rowCount: meta?.totalRowCount ?? 0,
    renderRowActions: ({ table, row, }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    localization: {actions: 'Azioni',
      and: 'e',
      cancel: 'Cancella',
      changeFilterMode: 'Cambia modalità filtro',
      changeSearchMode: 'Cambia modalità ricerca',
      clearFilter: 'Cancella filtro',
      clearSearch: 'Cancella ricerca',
      clearSelection: 'Cancella selezione',
      clearSort: 'Cancella ordinamento',
      clickToCopy: 'Click per copiare',
      copy: 'Copia',
      collapse: 'Chiudi',
      collapseAll: 'Chiudi tutto',
      columnActions: 'Azioni colonna',
      copiedToClipboard: 'Copia negli appunti',
      dropToGroupBy: 'Crea gruppo da {column}',
      edit: 'Modifica',
      expand: 'Espandi',
      expandAll: 'Espandi tutto',
      filterArrIncludes: 'Includi',
      filterArrIncludesAll: 'Includi tutto',
      filterArrIncludesSome: 'Inclusioni',
      filterBetween: 'Tra',
      filterBetweenInclusive: 'Tra inclusioni',
      filterByColumn: 'Filtra da {column}',
      filterContains: 'Contiene',
      filterEmpty: 'Vuoto',
      filterEndsWith: 'Finisce con',
      filterEquals: 'Uguale',
      filterEqualsString: 'Uguale',
      filterFuzzy: 'Sfocato',
      filterGreaterThan: 'Maggiore di',
      filterGreaterThanOrEqualTo: 'Maggiore o uguale a',
      filterInNumberRange: 'Tra',
      filterIncludesString: 'Contiene',
      filterIncludesStringSensitive: 'Contiene',
      filterLessThan: 'Minore di',
      filterLessThanOrEqualTo: 'Minore o uguale a',
      filterMode: 'Modalità filtro: {filterType}',
      filterNotEmpty: 'Non vuoto',
      filterNotEquals: 'Non uguale',
      filterStartsWith: 'Inizia con',
      filterWeakEquals: 'Uguale',
      filteringByColumn: 'Filtrando da {column} - {filterType} {filterValue}',
      goToFirstPage: 'Vai alla prima pagina',
      goToLastPage: "Vai all'ultima pagina",
      goToNextPage: 'Vai alla pagina successiva',
      goToPreviousPage: 'Vai alla pagina precedente',
      grab: 'Prendi',
      groupByColumn: 'Raggruppa da {column}',
      groupedBy: 'Raggruppa da ',
      hideAll: 'Nascondi tutto',
      hideColumn: 'Nascondi la colonna {column}',
      max: 'Massimo',
      min: 'Minimo',
      move: 'Sposta',
      noRecordsToDisplay: 'Nessun record da mostrare',
      noResultsFound: 'Nessun risultato trovato',
      of: 'di',
      or: 'o',
      pin: 'Blocca',
      pinToLeft: 'Blocca a sinistra',
      pinToRight: 'Blocca a destra',
      resetColumnSize: 'Resetta dimensione colonna',
      resetOrder: 'Resetta ordine',
      rowActions: 'Azioni riga',
      rowNumber: '#',
      rowNumbers: 'Righe',
      rowsPerPage: 'Righe per pagina',
      save: 'Salva',
      search: 'Cerca',
      selectedCountOfRowCountRowsSelected:
        '{selectedCount} di {rowCount} righe selezionate',
      select: 'Seleziona',
      showAll: 'Mostra tutto',
      showAllColumns: 'Mostra tutte le colonne',
      showHideColumns: 'Mostra/Nascondi colonne',
      showHideFilters: 'Mostra/Nascondi filtri',
      showHideSearch: 'Mostra/Nascondi ricerca',
      sortByColumnAsc: 'Ordina da {column} crescente',
      sortByColumnDesc: 'Ordina da {column} decrescente',
      sortedByColumnAsc: 'Ordinato da {column} crescente',
      sortedByColumnDesc: 'Ordinato da {column} decrescente',
      thenBy: ', poi da',
      toggleDensity: 'Cambia densità',
      toggleFullScreen: 'Cambia schermo intero',
      toggleSelectAll: 'Cambia selezione totale',
      toggleSelectRow: 'Cambia selezione riga',
      toggleVisibility: 'Cambia visibilità',
      ungroupByColumn: 'Rimuovi gruppo {column}',
      unpin: 'Sblocca',
      unpinAll: 'Sblocca tutto',
    },
    renderCreateRowDialogContent: ({table, row,})=>(
      <>
      <DialogTitle variant="h4">Crea nuovo utente</DialogTitle>
      <br />
      <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', mt:'8px'}}
          >
          <TextField fullWidth
          type="text" 
          label="username" 
         // size='small' 
          defaultValue="Small" 
          id="username" 
          name="username" 
          autoFocus
          onChange={(e) =>
            setUserValues({ ...userValues, [e.target.name]: e.target.value })
          }
          required />
         
          <TextField
              fullWidth type="text" label="nome" 
              //size='small' 
              defaultValue="Small" id="nome" name="nome" autoFocus
              required
              margin="dense" 
               onChange={(e) =>
            setUserValues({ ...userValues, [e.target.name]: e.target.value })
          }
           />
           
            <TextField fullWidth type="text" label="cognome"
            // size='small' 
            defaultValue="Small" id="cognome" name="cognome" autoFocus
            onChange={(e) =>
              setUserValues({ ...userValues, [e.target.name]: e.target.value })
            }
            required />

            <TextField fullWidth type="text" label="email" 
            //size='small'
             defaultValue="Small" id="email" name="email" autoFocus
            onChange={(e) =>
              setUserValues({ ...userValues, [e.target.name]: e.target.value })
            }  
            required />
            <TextField fullWidth type="text" label="password" 
            //size='small' 
            defaultValue="Small" id="password" name="password" autoFocus
            onChange={(e) =>
              setUserValues({ ...userValues, [e.target.name]: e.target.value })
            }  
            required />
      
            {/* Nuovo campo select per il ruolo */}
            <FormControl fullWidth margin="normal" required>
              <InputLabel id="role-label">Ruolo</InputLabel>
              <Select labelId="role" fullWidth
                label={"Role"}
                id="role"
                name="role"
                value={options}
                onChange={(e) =>
                  setUserValues({ ...userValues, [e.target.name]: e.target.value })
                }
              >
                <MenuItem value={"user"}>User</MenuItem>
                <MenuItem value={"admin"}>Admin</MenuItem>
                <MenuItem value={"manager"}>Manager</MenuItem>
              </Select>
            </FormControl>
  
            <FormControl>
   
              <FormControlLabel
                control={
                  <Checkbox
                    name="active"
                    //   checked={formData.active}
                    onChange={(e) =>
                      setUserValues({ ...userValues, [e.target.name]: e.target.value })
                    }
                    color="primary"
                  />
                }
                label="Attivo"
              />
            </FormControl>
  
          </DialogContent>
          <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
        </>
    ),
    onCreatingRowSave: handleCreateUser,
    //optionally customize modal content

    renderTopToolbarCustomActions: ({ table }) => (

      <Button
      startIcon={<PersonAddAltIcon />}
      variant="outlined"
      color='lime'
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
        }}
      >
        Create Utente
      </Button>

    ),
    state: {
      //  columnFilters,
      //  globalFilter,
      isLoading: isLoadingUsers,
      isFetching: isFetchingUsers,
      //   pagination, 
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isRefetching,
      //  sorting,
    },
  });

  return <MaterialReactTable table={table} />;
};

const AppUsers = () => {
  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      <Utenti />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default AppUsers;

//READ hook (get users from api)

function useGetUsers() {
  return useQuery({
    queryKey: ['users'],
    queryFn: fetchUsers,
    refetchOnWindowFocus: false,
  });
}

//CREATE hook (post new user to api)

function XXuseCreateUser(userValues) {

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addUsers(userValues),
    //client side optimistic update
/*     onMutate: (newUserInfo) => {
      queryClient.setQueryData(
        ['users'],
        (prevUsers) =>
          [
            ...prevUsers,
            {
              ...newUserInfo,
              id: (Math.random() + 1).toString(36).substring(7),
            },
          ],
      );
    }, */
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
  });

}

