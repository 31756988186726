
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
    QueryClientProvider,
    QueryClient,
  } from '@tanstack/react-query'; //note: this is TanStack React Query V5
import AppUsers from './AppUsers';


const queryClient = new QueryClient();

const ManagedUsers = () => {

      return(
        <QueryClientProvider client={queryClient}>
        <AppUsers />
        <ReactQueryDevtools initialIsOpen={true} />
        </QueryClientProvider>
     )
}

export default ManagedUsers;