import React, {useMemo, useCallback, useState, useRef, useEffect, useContext} from 'react';
//import ufficiPostali from './ufficiPostali.json';
import depositiShort from './depositiShort.json';
import ufficiPostaliShort from './ufficiPostaliShort.json'
import { ThemeContext } from '../context/ThemeContext'; // Assicurati che il percorso sia corretto

import {
  AdvancedMarker,
  useMap,
  APIProvider,
  InfoWindow,
  Map,
  Pin,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import {MarkerClusterer} from '@googlemaps/markerclusterer';
import {Marker} from '@googlemaps/markerclusterer';
//import {getData} from './data';

import ControlPanel from './control-panel';

import './examples.css';

//const API_KEY = GOOGLE_MAPS_API_KEY ?? (process.env.GOOGLE_MAPS_API_KEY);
const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;


const MappX = (props) => {

  const dxz = useMemo(() => ufficiPostaliShort, []);

  const depoShort = useMemo(() => depositiShort, []);

  const {darkMode, toggleDarkMode } = useContext(ThemeContext);

  const position = {lat: parseFloat(41.924598693847656), lng: parseFloat(12.445199012756348)};
  const style = { width: "100%", height: "90vh" };

  const [infoWindowShown, setInfoWindowShown] = useState(true);
  
  //console.log(ufficiPostali)
  const onMapClick = useCallback(() => {
    setInfoWindowShown(!infoWindowShown);
  }, [infoWindowShown]);
 


  return (
    <div className="App" style={style}>
    <APIProvider apiKey={REACT_APP_GOOGLE_MAPS_API_KEY} onLoad={() => console.log('Maps API has loaded.')} libraries={['marker']} >
    {darkMode && (
   
   
    <Map
        mapId='67cf8c0012e507fa'
        defaultZoom={6}
        defaultCenter={position}
        gestureHandling={'greedy'}
        onClick={onMapClick}
        clickableIcons={false}
        disableDefaultUI
        colorScheme={"DARK"}       
        >

        <PoiMarkers pois={dxz} />
        <PoiDepoMarkers pois={depoShort} windowOpen={infoWindowShown} />
        
      </Map>
    )}
    {!darkMode && (
   
   
      <Map
          mapId='67cf8c0012e507fa'
          defaultZoom={6}
          defaultCenter={position}
          gestureHandling={'greedy'}
          onClick={onMapClick}
          clickableIcons={false}
          disableDefaultUI
          colorScheme={"LIGTH"}       
          >
  
          <PoiMarkers pois={dxz} />
          <PoiDepoMarkers pois={depoShort} windowOpen={infoWindowShown} />
          {/*<ControlPanel />*/}
        </Map>
      )}

    </APIProvider>
      </div>
  );
};

const PoiMarkers = (props) => {
  const map = useMap();
  const [markers, setMarkers] = useState({});
  const clusterer = useRef(null);
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const [selectedLatLon, setSelectedLatLon] = useState({});
  const [info, setInfo]= useState({});
  const {darkMode } = useContext(ThemeContext);
  // Initialize MarkerClusterer, if the map has changed
  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({map});
    }
  }, [map]);

  // Update markers, if the markers array has changed
  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker, key) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers(prev => {
      if (marker) {
        return {...prev, [key]: marker};
      } else {
        const newMarkers = {...prev};
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  const handleClick = useCallback((ev, val) => {
    setInfo(val)
    if(!map) return;
    if(!ev.latLng) return;
    console.log('marker clicked:', ev.latLng.toString());
    map.panTo(ev.latLng);
    const mYlatlng = {lat: parseFloat(val.location.lat), lng: parseFloat(val.location.lng)}
    setSelectedLatLon(mYlatlng);
    setInfowindowOpen(true);
    
  },[]);

  return (
    <>
      {props.pois.map((poi, index) => (
        <AdvancedMarker
          key={index}
          position={poi.location}
          clickable={true}
          onClick={(e)=>handleClick(e, poi)}
          ref={markerRef}
         // onClick={() => setInfowindowOpen(true)}
          >
        <Pin background={'#FBBC04'} glyphColor={'#0033cc'} borderColor={'#0033cc'} />
        </AdvancedMarker>

      ))}
  const {darkMode } = useContext(ThemeContext);
  {infowindowOpen && selectedLatLon &&  (
        <InfoWindow
          //anchor={marker}
          position={{lat: parseFloat(selectedLatLon.lat), lng: parseFloat(selectedLatLon.lng)}}
          maxWidth={300}
          onCloseClick={() => setInfowindowOpen(false)}>
          
          {info && darkMode && (
            <><h4 style={{color: 'black'}}>Ufficio Postale{' '}</h4>
            <ul style={{color: 'black'}}>
            <li>{info?.uffici}</li>
            {/*<li>{info?.location.lat}, {info.location.lng}</li>*/}
            </ul> </>
          )}
          {info && !darkMode && (
            <><h4 style={{color: 'black'}}>Ufficio Postale{' '}</h4>
            <ul style={{color: 'black'}}>
            <li>{info?.uffici}</li>
            {/*<li>{info?.location.lat}, {info.location.lng}</li>*/}
            </ul>  </>
          )}

        </InfoWindow>
      )}
    </>
  );
};

const PoiDepoMarkers = (props) => {
const map = useMap();
const [markers, setMarkers] = useState({});
const clusterer = useRef(null);
const [infowindowOpen, setInfowindowOpen] = useState(false);
const [markerRef, marker] = useAdvancedMarkerRef();
const [selectedLatLon, setSelectedLatLon] = useState({})
const [info, setInfo]= useState({})
// Initialize MarkerClusterer, if the map has changed
const {darkMode } = useContext(ThemeContext);
const windowOpen = ()=> {
  setInfowindowOpen(props.windowOpen);
}
useEffect(() => {
  if (!map) return;
  if (!clusterer.current) {
    clusterer.current = new MarkerClusterer({map});
  }
}, [map]);

// Update markers, if the markers array has changed
useEffect(() => {
  clusterer.current?.clearMarkers();
  clusterer.current?.addMarkers(Object.values(markers));
}, [markers]);

const setMarkerRef = (marker, key) => {
  if (marker && markers[key]) return;
  if (!marker && !markers[key]) return;

  setMarkers(prev => {
    if (marker) {
      return {...prev, [key]: marker};
    } else {
      const newMarkers = {...prev};
      delete newMarkers[key];
      return newMarkers;
    }
  });
};


const handleClick = useCallback((ev, val, val2) => {
  setInfo(val);
  if(!map) return;
  if(!ev.latLng) return;
  console.log('marker clicked:', ev.latLng.toString());
  map.panTo(ev.latLng);
 const mYlatlng = {lat: parseFloat(val.location.lat), lng: parseFloat(val.location.lng)}
  setSelectedLatLon(mYlatlng);
  setInfowindowOpen(true);
}, [map]);

return (
  <>
    {props.pois.map((poi, index) => (
      <AdvancedMarker
        key={index}
        position={{lat: parseFloat(poi.location.lat), lng: parseFloat(poi.location.lng)}}
        clickable={true}
        onClick={(e)=>handleClick(e, poi, marker)}
        ref={markerRef}

       // onClick={() => setInfowindowOpen(true)}
        >
      <Pin background={'#0033cc'} glyphColor={'#FBBC04'} borderColor={'#FBBC04'} />
      </AdvancedMarker>

    ))}


    {infowindowOpen && selectedLatLon && (
 
      <InfoWindow
        //anchor={marker}
        
        position={{lat: parseFloat(selectedLatLon.lat), lng: parseFloat(selectedLatLon.lng)}}
        maxWidth={300}
        onCloseClick={() => setInfowindowOpen(false)}>
        
        {info &&  darkMode && (
          <><h4 style={{color: 'black'}}>Deposito{' '}</h4>
          <ul style={{color: 'black'}}>
          <li>{info.depositi}</li>
          {/*<li>{info?.location.lat}, {info?.location.lng}</li>*/}
          </ul> </>
        )}    
        {info &&  !darkMode && (
          <><h4 style={{color: 'black'}}>Deposito{' '}</h4>
          <ul style={{color: 'black'}}>
          <li>{info.depositi}</li>
          {/*<li>{info?.location.lat}, {info?.location.lng}</li>*/}
          </ul> </>
        )}     
      </InfoWindow>
      
    )}
   
  </>
);
};

export default MappX;

