import React, { useEffect, useState } from 'react';
import { Box, Typography, Container, CircularProgress, Button } from '@mui/material';
import { responsiveFontSizes, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import axios from 'axios';
import {getCampaignsAffissioni, getDepostiRegions, getDepositiCities} from './apiDepositi'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Stack from '@mui/material/Stack';
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from '@mui/x-charts/Gauge';
import CardContent from '@mui/material/CardContent';

function GaugePointer() {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState();

  if (valueAngle === null) {
    // No value to display
    return null;
  }

  const target = {
    x: cx + outerRadius * Math.sin(valueAngle),
    y: cy - outerRadius * Math.cos(valueAngle),
  };
  return (
    <g>
      <circle cx={cx} cy={cy} r={5} fill='#52b202' />
      <path
        d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
        stroke="red"
        strokeWidth={3}
      />
    </g>
  );
}


const ManagedStatDepositi = () => {
  const [campaignMenu, setCampaignMenu] = useState([]);
  const [campaignSelected, setCampaignSelected] = useState('');
  const [campaignData, setCampaignData] = useState([]);
  const [campaignOveralData, setCampaignOveralData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [regioni, setRegioni] = useState([]);
  const [citta, setCitta] = useState([]);
  const [selectedRegione, setSelectedRegione] = useState("");
  const [selectedCitta, setSelectedCitta] = useState('');
  const [notFound, setNotFound] = useState('');
  const { REACT_APP_API_ENDPOINT } = process.env;

  const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

// Per recuperare le campagne disponibili dal backend
useEffect(() => {
    const fetchCampaignProgress = async () => {
      try {
        const response = await getCampaignsAffissioni();
        console.log('Campaign:', response);
        setCampaignMenu(response);
      } catch (error) {
        console.error('Error fetching campaign progress:', error);
      }
    };
    
    // Usa l'ID della campagna che vuoi testare
    fetchCampaignProgress('66f852d3bf7b41110a6d0323'); // Assicurati che questo ID esista nel database
  }, []);

  // Per recuperare le regioni disponibili dal backend
  useEffect(() => {
    const fetchRegioni = async () => {
      try {
        const response = await getDepostiRegions();
        setRegioni(response);
      } catch (error) {
        console.error('Errore nel recupero delle regioni:', error);

      }
    };
    fetchRegioni();
  }, []);

  // Quando una regione è selezionata, recupera le città corrispondenti
  useEffect(() => {
    if (selectedRegione) {
      const fetchCitta = async () => {
        try {
          const response = await getDepositiCities(selectedRegione)
          setCitta(response);
        } catch (error) {
          console.error('Errore nel recupero delle città:', error);
        }
      };

      fetchCitta();
    }
  }, [selectedRegione]);



  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  function chartSetting(labels, media){

      return  {
    yAxis: [
      {
        label: `${labels}`,
      },
    ],
    width: 250,
    height: 300,
    sx: {[`.${axisClasses.left} .${axisClasses.label}`]: {transform: 'translate(-8px, 10)',},
    },
  };
  }
  const colors = [
  '#02b2af',
  '#FF8F33',
  '#72ccff',
  '#B833FF',
  '#33FFF5',
  '#da00ff',
  '#FF33A1',

];


/*
/api/depositi-managed/stat/${campaignId}/overall-progress
*/

const fetchCampaignOveralProgress = async (campaignId) => {
      try {
       // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns/${campaignId}/overall-progress`, {
       // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/depositi-managed/stat/${campaignId}/overall-progress`, {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/mystat/depositi-managed/overall/${campaignSelected}?regione=${selectedRegione}&localita=${selectedCitta}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log('Campaign Progress:', response.data);
        setCampaignOveralData(response.data);
      } catch (error) {
        console.error('Error fetching campaign progress:', error);
      }
}

/*
api/depositi-managed?campaignId=${campaignId}
GET /api/mystat/depositi-managed?campaignId=66f852d3bf7b41110a6d0323&regione=EMILIA%20ROMAGNA&localita=BOLOGNA

*/

const fetchCampaignProgress = async (campaignId) => {
      try {
       // const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/campaigns/${campaignId}/progress`, {
      //  const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/depositi-managed?campaignId=${campaignId}`, {
        const response = await axios.get(`${REACT_APP_API_ENDPOINT}/api/mystat/depositi-managed?campaignId=${campaignSelected}&regione=${selectedRegione}&localita=${selectedCitta}`);
        console.log('Campaign Progress:', response.data);
        setCampaignData(response.data);
        fetchCampaignOveralProgress();
        setNotFound('');
      } catch (error) {
        console.error('Error fetching campaign progress:', error);
        if (error.response.data.message) {
          setNotFound(error.response.data.message);
        }
        setCampaignData([]);
        setCampaignOveralData([]);
        setLoading(false)
      }
}

const handleCampaignId = (event) => {
  setCampaignSelected(event.target.value);
};

const handleChange = (event) => {
  console.log(campaignMenu);
  
  fetchCampaignProgress ()
  }; 

const MenuCampagne = () => {

  return (
  <Grid container spacing={1} style={{marginBottom: '25px'}}>

  <Grid size={12}>
  <Grid item  xs={12} sm={5} md={4}>
  <FormControl fullWidth>
  <InputLabel id="demo-label-campagna">Seleziona Campagna</InputLabel>
  <Select

  labelId="demo-simple-select-label"
  label="Seleziona Campagna"
  id="demo-simple-select"
    value={campaignSelected}
    onChange={handleCampaignId}
  >
  {campaignMenu?.map((option, index) => {
          return (
            <MenuItem key={option._id} value={option._id}>
              {option.denominazione}
            </MenuItem>
          )
})}

  </Select>
  </FormControl>
  </Grid>
  </Grid>

  <Grid size={12}>
    <Grid item  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="regione-label">Seleziona Regione</InputLabel>
              <Select
              style={{padding:'4px 4px'}}
                value={selectedRegione}
                onChange={(e) => setSelectedRegione(e.target.value)}
                labelId="regione-select-label"
                label={"Seleziona Regione"}
              >
                {regioni.map((regione) => (
                  <MenuItem key={regione} value={regione}>
                    {regione}
                  </MenuItem>
                ))}
                <MenuItem value={''} onChange={(e) => setSelectedRegione('')} style={{color:'red', fontWeight:'bolder'}}>NULL</MenuItem>
              </Select>
            </FormControl>
          </Grid>

        </Grid>

        <Grid size={12}>
        <Grid item  xs={12} sm={5} md={4}>
          <FormControl fullWidth >
              <InputLabel id="citta-label">Seleziona Città</InputLabel>
              <Select
              style={{padding:'4px 4px'}}
             // defaultValue={""}
             placeholder='Fuel Type'
             name={'Fuel Type'}
                value={selectedCitta === -1 ? " " :  selectedCitta}
                onChange={(e) => setSelectedCitta(e.target.value)}
                disabled={!selectedRegione} // Disabilita il selettore città finché la regione non è selezionata
                labelId="citta-select-label"
                label={"Seleziona città"}
                >
                {citta?.map((city) => (
                  <MenuItem key={city} value={city}>
                    {city}
                  </MenuItem>
                ))}
                <MenuItem value={''} onChange={(e) => setSelectedCitta('')} style={{color:'red', fontWeight:'bolder'}}>NULL</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button variant="outlined"
        onClick={handleChange}>Filtra dati</Button>
  </Grid>
  )
}
//  [totalCar, completedCars, inProgressCars, completionRate] = campaignOveralData;

  return (
<>
<Container >
<Box sx={{ padding: '0 !important' }}>
<Stack alignItems="center" direction="row" gap={2} sx={{ mb:4 }}>
  <LocalShippingIcon />
  <Typography variant="body1"> Dashboard progresso campagna depositi managed</Typography>
</Stack>
 
{MenuCampagne()}
{notFound}

{campaignOveralData &&

  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={2} mb={8}>
  <Grid size={6}>
    <Item>
    <Grid container direction="row"
  alignItems="center"
  justifyContent="center"
>
      <CardContent >
      <h3 id="battery_level_label">
        {campaignOveralData.length === 0 ? 0 : `Overall Progress depositi: ${campaignOveralData[0].completionRateDepositi.toFixed(2)}%`}
      </h3>
      <GaugeContainer
        width={400}
        height={200}
        startAngle={-110}
        endAngle={110}
        value={campaignOveralData.length === 0 ? 0 : campaignOveralData[0].completionRateDepositi}
        aria-labelledby="battery_level_label"
       // style={{marginLeft: '17%'}}
      >
        <GaugeReferenceArc />
        <GaugeValueArc />
        <GaugePointer />
      </GaugeContainer>
      <Typography gutterBottom sx={{ color: 'orangered', fontSize: 14 }}>
        {campaignOveralData.length === 0 ? 0 : `Depositi: ${ campaignOveralData[0].totalDepositi}, Completati: ${(campaignOveralData[0].totalGaragesTrattati)}`}
      </Typography>
      
    </CardContent>

</Grid>

    </Item>
  </Grid>
  <Grid size={6}>
    <Item>
    <Grid container direction="row"
        alignItems="center"
        justifyContent="center"
      >
      <CardContent >
      <h3 id="level_label">
      {campaignOveralData.length === 0 ? 0 : `Overall Progress mezzi: ${campaignOveralData[0].completionRateCars.toFixed(2)}%`}
      </h3>
      <GaugeContainer
        width={400}
        height={200}
        startAngle={-110}
        endAngle={110}
        value={campaignOveralData.length === 0 ? 0 : campaignOveralData[0].completionRateCars}
        aria-labelledby="level_label"
       // style={{marginLeft: '17%'}}
      >
        <GaugeReferenceArc />
        <GaugeValueArc />
        <GaugePointer />
      </GaugeContainer>
      
      <Typography gutterBottom sx={{ color: 'lime', fontSize: 14 }}>
        { campaignOveralData.length === 0 ? 0 : `Mezzi Totali: ${campaignOveralData[0].totalCars}, Completati: ${(campaignOveralData[0].totalCarsTrattate)}`}
      </Typography>
    </CardContent>

</Grid>

    </Item>
  </Grid>
</Grid>
}

{campaignData && 
  <div>
  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

  {campaignData.map((progress, index) => (

    <Grid  key={index} size={{ xs: 12, md: 3 }}>
      <Item>

      <BarChart style={{marginLeft: '23px'}}
      //  dataset={progress}
      xAxis={[{ scaleType: 'band', data: ['completed', 'n° auto'], colorMap: {
        type: 'ordinal',
        colors,
      },}]}
      //  xAxis={[{ scaleType: 'band', dataKey: "garage" }]}
      series={[{ data: [progress.numero_auto, progress.numero_auto], label: `${100}% completato` }]}
      slotProps={{ legend: { hidden: false } }}
      barLabel="value"
      /*
        series={[
          { dataKey: progress.completed, label: 'completed', fill: '#82ca9d' },
          { dataKey: progress.in_progress, label: 'in progress', fill: '#8884d8' },
          { dataKey: progress.total, label: 'tot. mezzi', fill: ' #DAF7A6' }
        ]}
*/
       {...chartSetting(progress.garageInfo.recapito, progress.media)}
      />
            
      </Item>
    </Grid>

          ))}


  </Grid>
</div>
}
</Box>

</Container>
</>





  );
};

export default ManagedStatDepositi;
