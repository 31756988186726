
const { REACT_APP_API_ENDPOINT } = process.env;
export async function fetchUsers(){
    const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/users/getall`);
    return response.json();
}

export async function addUsers(userData){
    const response = await fetch(`${REACT_APP_API_ENDPOINT}/api/users/register`,{
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(userData) 
    });
    if (response.status === 401) {
        return Promise.reject(new Error("Username o email già esistenti. Si prega di utilizzare un altro username o email."))
    }
    if (response.status === 400 || response.status === 500) {
        return Promise.reject(new Error("Ooops!"))
    }
    return response.json();
}




/*
`${REACT_APP_API_ENDPOINT}/api/offices/create`
*/