import React, {useState, useEffect} from 'react';
import { Box, Typography } from '@mui/material';
import getUserImages from '../services/userImagesService';  // Importa il servizio
const { REACT_APP_API_IMAGE } = process.env;

const Gallery = () => {

  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);

 
  useEffect(() => {
    // Funzione per recuperare le immagini al caricamento del componente
    const fetchImages = async () => {
      try {
        const fetchedImages = await getUserImages();  // Chiamata al servizio
        setImages(fetchedImages);
      } catch (err) {
        setError('Errore nel caricamento delle immagini');
      }
    };

    fetchImages();
  }, []);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Galleria Immagini</Typography>
      <div>
      <h2>Le tue immagini</h2>
      {error && <p>{error}</p>}
      <div>
        {images.length > 0 ? (
          <ul>
            {images.map((image, index) => (
              <li key={index}>
                <img src={`${REACT_APP_API_IMAGE}/${image.path}`} alt={image.filename} width="200" />
              </li>
            ))}
          </ul>
        ) : (
          <p>Non ci sono immagini da visualizzare.</p>
        )}
      </div>
    </div>
      <Typography variant="body1">Qui puoi vedere le immagini caricate.</Typography>
    </Box>
  );
};

export default Gallery;
