import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { NotificationProvider } from './utils/NotificationContext'; // Importa il provider

import { AuthProvider } from './hooks/useAuth';
import { ThemeProvider } from './context/ThemeContext';
import createAuthInterceptor from './utils/authInterceptor';
import { SnackbarProvider } from 'notistack';
//import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const navigate = (path) => {
  window.location.href = path; // Fallback navigation method
};

const axiosInstance = createAuthInterceptor(navigate);


/*
const createAppTheme = (mode) => createTheme({
  palette: {
    mode: mode,
    primary: {
      main: mode === 'light' ? '#1976d2' : '#90caf9',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: mode === 'light' ? '#f5f5f5' : '#121212',
    },
  },
});
*/
const Root = () => {
 // const [mode, setMode] = useState('light');
 
 // const toggleTheme = () => {
//    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
 // };

 // const theme = createAppTheme(mode);

  return (
    <AuthProvider>
   <SnackbarProvider maxSnack={3}> 
   <ThemeProvider>
        <CssBaseline />
        <BrowserRouter>
        <NotificationProvider>

          <App axiosInstance={axiosInstance}/>

          </NotificationProvider>
        </BrowserRouter>
      </ThemeProvider>
   </SnackbarProvider> 
    </AuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
